<template>
    <b-modal id="create-question" title="Create Question">
        <b-container fluid>
            <div class="form-group">
                <label for="slug">Lang</label>
                <b-form-select v-model="lang" :options="options"></b-form-select>
                <div class="invalid-feedback" v-for="(text, i) in langErrors" :key="i">{{ text }}</div>
            </div>
            <div class="form-group">
                <label for="title">Question</label>
                <textarea v-model="question" class="form-control"  :class="{ 'is-invalid': questionErrors.length }" id="question"></textarea>
                <div class="invalid-feedback" v-for="(text, i) in questionErrors" :key="i">{{ text }}</div>
            </div>
            <div class="form-group">
                <label for="price">Answer</label>
                <textarea v-model="answer" class="form-control" :class="{ 'is-invalid': answerErrors.length }" id="answer"></textarea>
                <div class="invalid-feedback" v-for="(text, i) in answerErrors" :key="i">{{ text }}</div>
            </div>
        </b-container>    
        <template v-slot:modal-footer="{ cancel }">
            <b-button variant="primary" @click="save">Send</b-button>
            <b-button  @click="cancel()">Cancel</b-button>
        </template>
    </b-modal>  
</template>
<script>
export default {
    data() {
        return {
            options: [
                { value: null, text: 'None' }, 
                { value: 'en', text: 'En' }, 
                { value: 'ja', text: 'Ja' }
            ],
            lang: null,
            langErrors: [],
            question: null,
            questionErrors: [],
            answer: null,
            answerErrors: []
        }
    },
    methods: {
        show() {
            this.lang = null;
            this.langErrors = [];
            this.question = null;
            this.questionErrors = [];
            this.answer = null;
            this.answerErrors = [];

            this.$bvModal.show('create-question');
        },
        save() {
            this.$http.post('faq', {
                'lang': this.lang,
                'question': this.question,
                'answer': this.answer
            }).then(this.responseSuccess, this.responseError);
        },
        responseSuccess() {
            this.$bvModal.hide('create-question');
            this.$emit('saved', true);
        },
        responseError(res) {
            this.langErrors = this.getValidationError(res, 'lang');
            this.questionErrors = this.getValidationError(res, 'question');
            this.answerErrors = this.getValidationError(res, 'answer');
     
            this.$emit('saved', false);
        },
        getValidationError(errorResp, errorSlug) {
            const data = errorResp.response.data;

            return data.hasOwnProperty('errors') && data.errors.hasOwnProperty(errorSlug) ? data.errors[errorSlug] : [];
        }
    },
    mounted() {
        this.item = this.resourceItem;
    }
}
</script>
