<template>
    <div>
        <navAdmin />
        <div class="container">
            <h3 class="h1 pt-4">
                <span>FAQ</span>
                <button v-if="$auth.check(admin.id)" @click="create" class="btn btn-primary float-right">Create</button>
            </h3>
            <div v-if="isError" class="alert alert-danger">{{ errorMessage  }}</div>
            <table v-if="!isError && items.length" class="table table-bordered mt-3">
                <thead>
                    <tr>
                        <th>Lang</th>
                        <th colspan="2">Question</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in items" v-bind:key="item.id">
                        <td>{{ item.lang }}</td>
                        <td>{{ item.question }}</td>
                        <td>
                            <button v-if="$auth.check(admin.id)" class="btn btn-danger float-right"  @click="deleteItem(item.id)">Delete</button>
                            <button class="btn btn-primary float-right mr-2" @click="update(item.id)">Edit</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p class="h4 pt-3" v-if="items.length == 0">Nothing found!</p>
        </div>
        <create ref="createModal" @saved="saved"/>
        <update ref="updateModal" :id="updateId" @saved="saved"/>
        <dropItem ref="deleteModal" :id="deleteId" @saved="saved" />
    </div>
</template>
<script>
import navAdmin from './../../components/nav-admin';
import create from './Create';
import dropItem from './Delete';
import update from './Update';
import { userType } from './../../mixins/user-type.mixin';

export default {
    mixins: [userType],
    data() {
        return {
            isError: false,
            errorMessage: null,
            items: [],
            updateId: null,
            deleteId: null
        }
    },
    components: { navAdmin, create, dropItem, update },
    methods: {
        get() {
            this.$http({ url: 'faq', method: 'GET'})
                .then(this.onGetSuccess, this.onRequestError);
        },
        onGetSuccess(response) {
            this.items = response.data.data;
        },
        onRequestError(response) {
            this.isError = true;
            this.errorMessage = response.data;
        },
        create() {
            this.$refs.createModal.show();
        },
        update(id) {
            this.updateId = id;
            this.$refs.updateModal.show();
        },
        deleteItem(id) {
            this.deleteId = id;
            this.$refs.deleteModal.show();
        },
        saved() {
            this.get();
        }
    },
    mounted() {
        this.get();
    }
}
</script>